import { generateMessage } from '../config/api';
import { IAIGenerateMessage } from '../models/ai';

import { BaseService } from './BaseService';

class AIService extends BaseService {
  public async generateMessage({
    creatorId,
    chatterId,
    data,
  }: IAIGenerateMessage): Promise<{ message: string; balanceAmount: number }> {
    return this.post(generateMessage(creatorId, chatterId), data);
  }
}

export const aiService = new AIService();
