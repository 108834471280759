import { useEffect } from 'react';

import { useEmojiProviderContext } from 'modules/electron/providers';

import { useWebviewContext, useVaultContext } from '../../../providers';
import { IOFWebviewHook } from '../../interfaces';

import useWebviewHandlers from './useWebviewHandlers';
import useAIMessages from './useAIMessages';

const useIpcMessages = ({ ref }: IOFWebviewHook) => {
  const { setIsHotbarModalOpen, setIsEmojiModalOpen } = useEmojiProviderContext();
  const { setWebviewUrl } = useWebviewContext();

  const { insertMessage, handleBackButton } = useWebviewHandlers({ ref });
  const { iaCopilotSend } = useAIMessages({ ref });

  const { setIsVaultOpen } = useVaultContext();

  useEffect(() => {
    if (!ref.current || !window.electronAPI) {
      return;
    }

    const run = (event: any) => {
      switch (event.channel) {
        case 'return-emojiPencil': {
          setIsHotbarModalOpen(true);
          break;
        }
        case 'return-emojiPopup': {
          setIsEmojiModalOpen(true);
          break;
        }
        case 'return-page-change': {
          if (event.args.at(0)?.payload) {
            const url = new URL(event.args.at(0)?.payload);
            setWebviewUrl(null);
            setWebviewUrl(url.pathname);
          }

          setIsHotbarModalOpen(false);
          setIsEmojiModalOpen(false);
          break;
        }
        case 'return-backButton': {
          handleBackButton();
          break;
        }

        case 'ai-copilot:send': {
          iaCopilotSend(event.args.at(0)?.payload);
          break;
        }

        case 'ai-copilot:accept': {
          insertMessage(event.args.at(0)?.payload ?? '', true);
          break;
        }

        case 'vault-media:open': {
          setIsVaultOpen(true);
          break;
        }

        case 'vault-media:close': {
          setIsVaultOpen(false);
          break;
        }
      }
    };

    ref.current.addEventListener('ipc-message', run);

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('ipc-message', run);
      }
    };
  }, [ref.current, iaCopilotSend]);
};

export default useIpcMessages;
